<template>
  <div>dfasdfasdfas</div>
</template>
<script>
  export default {
    name: "Main",
    components: {
    },
    setup() {
    }
  };
</script>
<style lang="scss" scoped>
</style>
